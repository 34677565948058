<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Články - Typy - Často kladené otázky - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
          label="Název"
          :validate-status="error('ale_name') ? 'error' : ''"
          :help="error('ale_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['ale_name', {rules: [{max: 60, message: 'Název nemůže být delší než 60 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Otázky a odpovědi"
          :required="true"
        >
          <a-row
            v-for="(k, index) in form.getFieldValue('items')"
            :key="index">
            <a-col :offset="2" :span="18">
              <a-form-item
                label="Otázka"
                :validate-status="error(`question[${index}]`) ? 'error' : ''"
                :help="error(`question[${index}]`) || ''"
              >
                <a-input v-decorator="[`question[${index}]`, { validateTrigger: ['change', 'blur'], rules: [{ required: true, message: 'Otázka musí být vyplněna!'}]}]"
                         placeholder="Otázka"
                >
                  <a-icon
                    slot="prefix"
                    type="question-circle"
                  />
                </a-input>
              </a-form-item>
              <a-form-item
                label="Odpověď"
                :validate-status="error(`answer[${index}]`) ? 'error' : ''"
                :help="error(`answer[${index}]`) || ''"
              >
                <a-input v-decorator="[`answer[${index}]`, { validateTrigger: ['change', 'blur'], rules: [{ required: true, message: 'Odpověď musí být vyplněna!' }]}]"
                         placeholder="Odpověď"
                         :autoSize="{ minRows: 4, maxRows: 10 }"
                         type="textarea"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-icon
                v-if="form.getFieldValue('items').length > 1"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="form.getFieldValue('items').length === 1"
                @click="() => removeItem(index)"
              />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <a-button
            class="m-auto d-block"
            type="dashed"
            @click="addItem"
            style="width: 200px;"
          >
            <a-icon type="plus" /> Přidat další
          </a-button>
        </a-form-item>

        <a-form-item
          label="Nadpis tučně"
          :validate-status="error('ale_title') ? 'error' : ''"
          :help="error('ale_title') || ''"
        >
          <a-input placeholder="Nadpis tučně" v-decorator="['ale_title', {rules: [{max: 60, message: 'Nadpis tučně nemůže být delší než 60 znaků!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Nadpis kurzivou"
          :validate-status="error('ale_title_thin') ? 'error' : ''"
          :help="error('ale_title_thin') || ''"
        >
          <a-input placeholder="Nadpis kurzivou" v-decorator="['ale_title_thin', {rules: [{max: 60, message: 'Nadpis kurzivou nemůže být delší než 60 znaků!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Popisek"
          :validate-status="error('ale_description') ? 'error' : ''"
          :help="error('ale_description') || ''"
        >
          <a-input
            type="textarea"
            :autoSize="{ minRows: 4, maxRows: 10 }"
            placeholder="Popisek"
            v-decorator="['ale_description']"
          />
        </a-form-item>

        <a-form-item label="Zveřejnit?">
          <a-checkbox v-decorator="['ale_is_active', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <a-form-item label="Přidat mezeru pod článek?">
          <a-checkbox v-decorator="['gap', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'

export default {
  components: {
    ActionTools, LanguageTab,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('items', { initialValue: [], preserve: true })
  },
  data() {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/article/type/faq',
          title: 'Seznam',
        },
      ],
      item: {
        languages: [],
      },
      formItemLayout: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    removeItem(index) {
      const items = this.form.getFieldValue('items')
      if (items.length === 1) {
        return
      }
      const questions = this.form.getFieldValue('question')
      const answers = this.form.getFieldValue('answer')
      items.splice(index, 1)
      questions.splice(index, 1)
      answers.splice(index, 1)
      this.form.setFieldsValue({
        items: items,
        question: questions,
        answer: answers,
      })
    },
    addItem() {
      const newIndex = this.form.getFieldValue('items').length
      this.form.getFieldDecorator(`question[${newIndex}]`)
      this.form.getFieldDecorator(`answer[${newIndex}]`)
      this.form.setFields({
        items: { value: this.form.getFieldValue('items').concat({ question: undefined, answer: undefined }) },
        ['question[' + newIndex + ']']: { value: undefined, errors: ['Otázka musí být vyplněna!'] },
        ['answer[' + newIndex + ']']: { value: undefined, errors: ['Odpověď musí být vyplněna!'] },
      })
      this.form.validateFields()
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('article/post', this.getData())
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        ate_type: 'FAQ',
        languages: this.item.languages.filter(x => x.ale_name !== undefined && x.ale_name.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            ale_title_thin: x.ale_title_thin !== undefined ? x.ale_title_thin : null,
            ale_content: x.ale_content,
            ale_description: x.ale_description !== undefined ? x.ale_description : null,
            ale_title: x.ale_title !== undefined ? x.ale_title : null,
            ale_name: x.ale_name !== undefined ? x.ale_name : null,
            ale_is_active: x.ale_is_active,
          }
        }),
      }
    },
    handleChange(oldLanguage) {
      const foundObj = { article: false }
      const newObj = {
        article: {
          lge_id: oldLanguage,
          ale_title_thin: this.form.getFieldValue('ale_title_thin'),
          ale_content: { gap: this.form.getFieldValue('gap'), items: this.form.getFieldValue('items').map((x, y) => { return { question: this.form.getFieldValue('question[' + y + ']'), answer: this.form.getFieldValue('answer[' + y + ']') } }) },
          ale_description: this.form.getFieldValue('ale_description'),
          ale_title: this.form.getFieldValue('ale_title'),
          ale_name: this.form.getFieldValue('ale_name'),
          ale_is_active: this.form.getFieldValue('ale_is_active'),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.article
          foundObj.article = true
          return true
        }
      })
      if (!foundObj.article) {
        this.item.languages.push(newObj.article)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
  created() {
    if (this.form.getFieldValue('items').length === 0) {
      this.addItem()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = { article: this.item.languages.find(x => x.lge_id === newValue) }
        let newObj
        if (foundObj.article === undefined) {
          newObj = {
            ale_title_thin: undefined,
            ale_description: undefined,
            ale_title: undefined,
            ale_name: undefined,
            ale_is_active: true,
            gap: true,
          }
          if (this.form.getFieldValue('items').length === 0) {
            this.addItem()
          }
        } else {
          newObj = {
            ale_title_thin: foundObj.article.ale_title_thin,
            gap: foundObj.article.ale_content.gap,
            ale_description: foundObj.article.ale_description,
            ale_title: foundObj.article.ale_title,
            ale_name: foundObj.article.ale_name,
            ale_is_active: foundObj.article.ale_is_active,
            items: foundObj.article.ale_content.items,
          }
          foundObj.article.ale_content.items.forEach((value, key) => {
            this.form.getFieldDecorator(`question[${key}]`)
            this.form.getFieldDecorator(`answer[${key}]`)
            this.form.setFields({
              ['question[' + key + ']']: { value: value.question, errors: value.question === undefined || value.question.trim().length === 0 ? ['Otázka musí být vyplněna!'] : undefined },
              ['answer[' + key + ']']: { value: value.answer, errors: value.answer === undefined || value.answer.trim().length === 0 ? ['Odpověď musí být vyplněna!'] : undefined },
            })
          })
        }
        this.form.setFieldsValue(newObj)
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
